import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'environments/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
@Injectable({
  providedIn: 'root'
})
export class CurrencyService {



  currency_service_endpoint = Config.serverUrl+'/currencies/';
  city_sservice_endpoint = Config.serverUrl+'/pays/';

  constructor(private httpClient: HttpClient) {}

  /**
   * Add new currency.
   * @body {Role} .
   * @return {Observable<Data>} return data with message and object created.
   */

  edit_currency(id,data): Observable<any> {

    return this.httpClient.post(this.currency_service_endpoint+id, data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
          return res
          
        // }
      })
    );

  }
  addNew_currency(data): Observable<any> {

    return this.httpClient.post(this.currency_service_endpoint,data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
          return res
          
        // }
      })
    );

  }


    /**
   * Get list currency.
   * @return {Observable<any>} List placeholder.
   */
     get_all_currency_(): Observable<any> {
      return this.httpClient.get<any>(this.currency_service_endpoint+'ar/').pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res['data'];
          }
        })
      );
    } 


  /**
   * Get list currency.
   * @return {Observable<any>} List placeholder.
   */
  get_all_currency(p): Observable<any> {
    return this.httpClient.get<any>(this.currency_service_endpoint+'ar/'+ p +'/10').pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  } 
  get_all_currency_per(p,per): Observable<any> {
    return this.httpClient.get<any>(this.currency_service_endpoint+'ar/'+ p +'/'+per).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  } 
  filtering_currency(p,data): Observable<any> {
    return this.httpClient.post(this.currency_service_endpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
/**
   * Get list currency.
   * @return {Observable<any>} List placeholder.
   */
 get_all_pays(): Observable<any> {
  return this.httpClient.get<any>(this.city_sservice_endpoint+'ar').pipe(
    map((res: any) => {
      if (res.status === 200) {
        console.log(res);
        return res['data'];
      }
    })
  );
} 
  get_currency_by_id(id): Observable<any> {
    return this.httpClient.get<any>(this.currency_service_endpoint+id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  } 

  delete_currency(id): Observable<any> {
    return this.httpClient.delete<any>(this.currency_service_endpoint+ id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  } 

  
  update_currency(id,data): Observable<any> {
    return this.httpClient.put(this.currency_service_endpoint + id , data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  } 
}
