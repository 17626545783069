import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "environments/config";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators";

@Injectable({
  providedIn: "root",
})
export class CountryService {
  country_service_endpoint = Config.serverUrl + "/countries/";

  constructor(private httpClient: HttpClient) {}

  /**
   * Add new country.
   * @body {Role} .
   * @return {Observable<Data>} return data with message and object created.
   */

  addNew_country(data, image): Observable<any> {
    const formData = new FormData();
    formData.append("english_name", data.english_name);
    formData.append("arabic_name", data.arabic_name);
    formData.append("pay", data.pay);
    formData.append("image", image);
    formData.append("countries_abbrev", data.countries_abbrev);
    formData.forEach((value, key) => {
      console.log(key + " " + value);
    });
    console.log("image in ", image);
    return this.httpClient
      .post(this.country_service_endpoint,formData)
      .pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return res;
          }
        })
      );
  }
  /**
   * Get list country.
   * @return {Observable<any>} List placeholder.
   */
  get_all_country(p,pe): Observable<any> {
    return this.httpClient.get<any>(this.country_service_endpoint + "all/ar/"+p+'/'+pe).pipe(
      map((res: any) => {
        if (res.status === 200) {
          return res["data"];
        }
      })
    );
  }
  filtering_country(p,data): Observable<any> {
    return this.httpClient.post(this.country_service_endpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }

  get_all_countryy(): Observable<any> {
    return this.httpClient.get<any>(this.country_service_endpoint + "ar").pipe(
      map((res: any) => {
        if (res.status === 200) {
          // console.log(res);
          return res["data"]['countries'];
        }
      })
    );
  }
  get_all_country_without_page(): Observable<any> {
    return this.httpClient.get<any>(this.country_service_endpoint + "ar/").pipe(
      map((res: any) => {
        if (res.status === 200) {
          // console.log(res);
          return res["data"];
        }
      })
    );
  }

  get_country_byid(id): Observable<any> {
    return this.httpClient
      .get<any>(this.country_service_endpoint + "getById/" + id)
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            console.log(res);
            return res["data"];
          }
        })
      );
  }

  delete_country(id): Observable<any> {
    return this.httpClient.delete<any>(this.country_service_endpoint + id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res["data"];
        }
      })
    );
  }

  update_country(id, data): Observable<any> {
    return this.httpClient.post(this.country_service_endpoint + id, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  }
  get_all_country_by_pay(pay: any): Observable<any> {
    return this.httpClient
      .post<any>(this.country_service_endpoint +"search/ar/"+ pay, {
        pay: pay,
      })
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            return res.data.countries;
          }
        })
      );
  }
}
