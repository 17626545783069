import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'environments/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';


@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  country_service_endpoints = Config.serverUrl + '/coupons/supplier/';
  coupon_service_endpoints = Config.serverUrl + '/coupons/';
  company_service_endpoints = Config.serverUrl + '/companies/';
  constructor(private httpClient: HttpClient) { }
  addNew_coupon(data): Observable<any> {
    return this.httpClient.post(this.coupon_service_endpoints + 'supplier/addcoupon', data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }
  filtering_coupon(p,data): Observable<any> {
    return this.httpClient.post(this.coupon_service_endpoints + 'filtering/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }
  /**
 * Get list currency.
 * @return {Observable<any>} List placeholder.
 */
  get_all_coupons_(): Observable<any> {
    return this.httpClient.get<any>(this.country_service_endpoints + '1/ar').pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  }
  get_all_coupons_pagination(p, per): Observable<any> {
    return this.httpClient.get<any>(this.country_service_endpoints + p + '/ar' + "/" + per).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res;
        }
      })
    );
  }
  get_all_coupons_admin(p, per): Observable<any> {
    return this.httpClient.get<any>(this.coupon_service_endpoints +"all/"+ p  + "/" + per+'/ar').pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res;
        }
      })
    );
  }
  get_all_coupons_admin_without(): Observable<any> {
    return this.httpClient.get<any>(this.coupon_service_endpoints + 'ar').pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  }
  get_all_companies_(): Observable<any> {
    return this.httpClient.get<any>(this.company_service_endpoints + 'ar').pipe(
      map((res: any) => {

        console.log(res);
        return res;

      })
    );
  }


  activate_coupon(id: any): Observable<any> {
    return this.httpClient
      .post<any>(this.coupon_service_endpoints + 'activer/' + id, { id: id }).pipe(
        map((res: any) => {

          return res;

        })
      );
  }
  
  activate_coupon_supp(id: any): Observable<any> {
    return this.httpClient
      .post<any>(this.coupon_service_endpoints + 'activer_coupon_by_supplier/' + id, { id: id }).pipe(
        map((res: any) => {

          return res;

        })
      );
  }
  get_coupon_byid(id): Observable<any> {
    return this.httpClient.get<any>(this.coupon_service_endpoints + 'byid_web/' + id + '/ar').pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res['data']);
          return res['data'];
        }
      })
    );
  }
  coupon_byid(id): Observable<any> {
    return this.httpClient.get<any>(this.coupon_service_endpoints + 'web/getbyid/' + id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  }
  update_(id, data): Observable<any> {
    return this.httpClient.put(this.coupon_service_endpoints + 'update/' + id, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  }

  update_admin(id, data): Observable<any> {
    return this.httpClient.put(this.coupon_service_endpoints + 'supplier/update/' + id, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  }
  addNew_coupon_admin(data): Observable<any> {
    return this.httpClient.post(this.coupon_service_endpoints + 'supplier/addcoupon_by_admin', data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }
  stat_coupon_admin(data): Observable<any> {
    return this.httpClient.post(this.coupon_service_endpoints + 'statistic', data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }
  delete_coupon(id): Observable<any> {
    return this.httpClient.delete<any>(this.coupon_service_endpoints + id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  }
  filter_coupon(data): Observable<any> {
    return this.httpClient.put(this.coupon_service_endpoints + 'filtering/ar', data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  }

}
