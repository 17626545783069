import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "environments/config";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators";

@Injectable({
  providedIn: "root",
})
export class EmploymentService {
  employment_service_endpoint = Config.serverUrl + "/employments/";
  employment_service_endpoint_status =
    Config.serverUrl + "/employments/account-status/";
  employment_demand_service_endpoint = Config.serverUrl + "/merchants/";

  constructor(private httpClient: HttpClient) {}
  get_all_employment(p, per): Observable<any> {
    return this.httpClient
      .get<any>(this.employment_service_endpoint + "all/ar/" + p + "/" + per)
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            // console.log(res);
            return res["data"];
          }
        })
      );
  }
  get_all_merchants(p,pe): Observable<any> {
    return this.httpClient
      .get<any>(this.employment_demand_service_endpoint + "all/ar/" + p+"/"+pe)
      .pipe(
        map((res: any) => {
          //console.log(res);
          return res["data"];
        })
      );
  }
  update_status(id, data): Observable<any> {
    return this.httpClient
      .patch(this.employment_service_endpoint_status + id, data)
      .pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return res;
          }
        })
      );
  }
  activate_merchant(id: any): Observable<any> {
    return this.httpClient
      .patch<any>(
        this.employment_demand_service_endpoint + "activate_merchant/" + id,
        { id: id }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  delete_employment(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(
        this.employment_service_endpoint + id)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  filtering_merchant(p,data): Observable<any> {
    return this.httpClient.post(this.employment_demand_service_endpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
        //   console.log(res);
        return res
        // }
      })
    );
  }
  filtering_demand(p,data): Observable<any> {
    return this.httpClient.post(this.employment_service_endpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
          console.log(res);
        return res
        // }
      })
    );
  }
}
