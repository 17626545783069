import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'environments/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  city_service_endpoint = Config.serverUrl+'/cities/';
  subcards_service_endpoint = Config.serverUrl+'/sub_card_types/';

  constructor(private httpClient: HttpClient) {}

  /**
   * Add new city.
   * @body {Role} .
   * @return {Observable<Data>} return data with message and object   created.
   */

  addNew_city(data): Observable<any> {
    return this.httpClient.post(this.city_service_endpoint, JSON.stringify(data) ).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
        }
      })
    );
  }
  addNew_subcard(data): Observable<any> {
    return this.httpClient.post(this.subcards_service_endpoint, data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
        }
      })
    );
  }

  /**
   * Get list city.
   * @return {Observable<any>} List placeholder.
   */
  get_all_city(p,pe): Observable<any> {
    return this.httpClient.get<any>(this.city_service_endpoint+'all/ar/'+ p +'/'+pe).pipe(
      map((res: any) => {
        if (res.status === 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }
  filtering_city(p,data): Observable<any> {
    return this.httpClient.post(this.city_service_endpoint + 'filter/ar/'+p, data).pipe(
      map((res: any) => {
        // if (res) {
        return res
        // }
      })
    );
  }
  get_all_citylist(): Observable<any> {
    return this.httpClient.get<any>(this.city_service_endpoint+'ar').pipe(
      map((res: any) => {
        if (res.status === 200) {
          // console.log(res);
          return res['data'];
        }
      })
    );
  }

  get_city_byid(id): Observable<any> {
    return this.httpClient.get<any>(this.city_service_endpoint+id+"/ar").pipe(
      map((res: any) => {
          console.log("city", res);
              return res['data']
      })
    );
  }
  get_city_byid_sans_lang(id): Observable<any> {
    return this.httpClient.get<any>(this.city_service_endpoint+"getbyid_without_lang/"+id).pipe(
      map((res: any) => {
          console.log("city", res);
              return res['data']
      })
    );
  }
  delete_city(id): Observable<any> {
    return this.httpClient.delete<any>(this.city_service_endpoint+ id).pipe(
      map((res: any) => {
        if (res.status === 200) {
          console.log(res);
          return res['data'];
        }
      })
    );
  }


  update_city(id,data): Observable<any> {
    return this.httpClient.post(this.city_service_endpoint + id , data).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res
          // if (res.code === HttpStatus.OK) {
          //   return true;
          // }
        }
      })
    );
  }
  get_all_city_by_coutry(country: any): Observable<any> {
    return this.httpClient
      .post<any>(this.city_service_endpoint +"search/ar/"+ country, {
        country: country,
      })
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            return res.data.cities;
          }
        })
      );
  }


}
